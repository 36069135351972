import React, { useState } from "react";
import PlayerDetails from "./PlayerDetails";
import { ButtonGroup, Input, Label } from 'reactstrap';

const ReassignPanel = (props) => {
  const [selectedResponse, setSelectedResponse] = useState(0);

  const setResponseId = (event) => {
    props.onValueChange(event.target.value);
  };

  let responseIndex = 0;
  const msg = `${props.condition.Title} needs assistance`;

  const imgPng = `${props.condition.Title.replace(/\s+/g, "")}.png`;

  return (
    <div className="component-Alert">
      <PlayerDetails condition={props.condition} />
      <div className="alert-Body">
        <h3>{msg}</h3>
        <div className="alert-Message">
          <p>
            I have finished my assigned tasks and am totally out of work to
            do. Would you like to release me from the project or would you
            like me to work on one of the Active Tasks listed below?
          </p>
          {/* <p>{props.condition["Message"]}</p> */}
        </div>

        <div className="alert-Response">
          <div className="form-group">
            <ButtonGroup
              name="conditions"
              vertical
              onChange={setResponseId}
            >
              {props.condition.ResponseList.map((row, index) => (
                <Label key={`label - ${index.toString()}`} check>
                  <Input
                    type="radio"
                    name="conditionGroup"
                    key={`chk - ${index}.toString()}`}
                    value={responseIndex}
                  />
                  {row[responseIndex++]}
                </Label>
              ))}
            </ButtonGroup>
          </div>
        </div>        
      </div>
    </div>
  );
};

export default ReassignPanel;
