import React from "react";
import { connect } from "react-redux";
import ReactHtmlParser from "react-html-parser";
import PlayerDetail from "./PlayerDetails";
import styled from "styled-components";

const PlayerDetailContainer = styled.div`
  margin-right: 0px !important;
  width: 80px !important;
`;

const ConditionComp = (props) => {
  let iterKey = 1;

  if (props.properties.isSilent && props.properties.isSilent === "1") {
    return <span />;
  }

  return (
    <div className="report-Item">
      <PlayerDetailContainer className="report-Item_Icon">
        <PlayerDetail player={props.properties} />
      </PlayerDetailContainer>
      <div className="report-Item_Content">
        {ReactHtmlParser(props.properties.message)}
        <p>
          <strong>
            <em>Your response:</em>
          </strong>{" "}
        </p>
        <p>{props.properties.response}</p>
        {Object.prototype.hasOwnProperty.call(props.properties, "condId") ? (
          <p>
            <small>{props.properties.condId}</small>
          </p>
        ) : (
          <p>
            <small />
          </p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  productId: state.auth.productId
});

export const Condition = connect(mapStateToProps, null)(ConditionComp);
