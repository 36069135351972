import React from 'react';
import ResourceImage from 'components/ResourceImage';

export const WorkerRemoved = props => {
	// if there was no new staffing activity, worker will be none, don't need
	// image or worker then, just the status
	var iterKey = 1;

	// eslint-disable-next-line no-return-assign
	return (
		<div className='report-Item'>
		<div className='report-Item_Icon'>
			<div className='resource-Profile'>
				<ResourceImage
					key={(iterKey += 1)}
					png={
						props.properties.worker === 'Buki'
							? 'Assistant.png'
							: `${props.properties.worker
									.replace(/\s+/g, '')
									.replace(/\./g, '')}.png`
					}
					alt={props.properties.worker}
				/>
			</div>
			<div className='resource-Body'>
			{props.properties.worker}
			</div>
		</div>
		<div className='report-Item_Content'>
		 Per request, {props.properties.worker} was removed from the resource plan but is still an available resource.

		</div>
	</div>


	);
};
