import React from "react";
import PlayerDetails from "./PlayerDetails";

export const AssistantPanel = (props) => {
  var iterKey = 1;
  return (
    <div className="component-Alert">
      <PlayerDetails condition={props.condition} />
      <div className="alert-Body">
        {props.playScriptList.map((s, index) => (
          <div key={iterKey++} id="message">
            {s.Script}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AssistantPanel;
