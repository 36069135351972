import React, { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import { ButtonGroup, Input, Label } from "reactstrap";
import PlayerDetails from "./PlayerDetails";

const ConditionPanel = (props) => {
  const [selectedResponse, setSelectedResponse] = useState(0);

  const setResponseId = (event) => {
    props.onValueChange(event.target.value);
  };

  let responseIndex = 1;
  return (
    <div className="component-Alert">
      <PlayerDetails condition={props.condition} />
      <div className="alert-Body">
        <p>
          <strong>{props.condition.Announcement}</strong>
        </p>
        <div className="alert-Message">
          {ReactHtmlParser(props.condition.Message)}
        </div>
        <div className="alert-Response">
          <div className="form-group">
            <ButtonGroup name="conditions" vertical onChange={setResponseId}>
              {props.condition.ResponseList.map((row, index) => (
                <Label check key={`label_${responseIndex.toString()}`}>
                  <Input
                    type="radio"
                    name="conditionGroup"
                    key={`radio_${index}`}
                    value={responseIndex}
                  />
                  {row[responseIndex++]}
                </Label>
              ))}
            </ButtonGroup>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionPanel;
